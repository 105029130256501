// retuns 7:00 PM
export const renderFormattedTime = (timeString) => {
  const utcTime = new Date(timeString);
  const estTime = new Date(utcTime.toLocaleString('en-US', { timeZone: 'America/New_York' }));
  return estTime.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
}; 

// returns Feb 21
export const renderFormattedDate = (timeString) => {
  const utcTime = new Date(timeString);
  const estTime = new Date(utcTime.toLocaleString('en-US', { timeZone: 'America/New_York' }));
  return estTime.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric'
  });
};

// returns grouping for books
export function groupLogs(logs) {
  const groupedLogs = [];
  for (let i = 0; i < logs.length; i += 2) {
    groupedLogs.push([logs[i], logs[i + 1]]);
  }
  return groupedLogs;
}

// New function that groups by game ID for NHL moneyline
export function groupLogsByGameId(logs) {
  if (!Array.isArray(logs) || logs.length === 0) {
    return [];
  }

  // Group by game ID or by game details if ID is not available
  const gameGroups = {};
  
  // First, group all logs by their game ID or a composite key
  logs.forEach(log => {
    if (!log) return; // Skip invalid logs
    
    // Create a composite key if id is not available
    const key = log.id || `${log.home_team}-${log.away_team}-${log.commence_time}`;
    
    if (!gameGroups[key]) {
      gameGroups[key] = [];
    }
    gameGroups[key].push(log);
  });
  
  // Convert the groups object to an array of arrays
  const groupedLogs = Object.values(gameGroups);
  
  // Sort each group by bookmaker to ensure consistent ordering
  groupedLogs.forEach(group => {
    group.sort((a, b) => {
      // Handle cases where bookmaker might be undefined
      const bookmakerA = (a?.bookmaker || '').toLowerCase();
      const bookmakerB = (b?.bookmaker || '').toLowerCase();
      return bookmakerA.localeCompare(bookmakerB);
    });
  });
  
  // Sort the groups by game time, with fallback for missing commence_time
  groupedLogs.sort((a, b) => {
    const timeA = a[0]?.commence_time ? new Date(a[0].commence_time) : new Date(0);
    const timeB = b[0]?.commence_time ? new Date(b[0].commence_time) : new Date(0);
    return timeA - timeB;
  });
  
  return groupedLogs;
}

export function groupGameLogs(logs) {
  const groups = {};

  for (let log of logs) {
    const key = `${log.home_team} vs ${log.away_team}`;

    if (!groups[key]) {
      groups[key] = {
        'DraftKings': null,
        'FanDuel': null
      };
    }

    groups[key][log.bookmaker] = log;
  }

  for (let key in groups) {
    if (!groups[key]['DraftKings']) {
      groups[key]['DraftKings'] = {
        home_team: key.split(' vs ')[0],
        away_team: key.split(' vs ')[1],
        bookmaker: 'DraftKings',
        totals_point: '--',
        home_team_price: '--',
        away_team_price: '--',
        under_price: '',
        over_price: '',
        // Add any other fields you want to display with default values
      };
    }
    if (!groups[key]['FanDuel']) {
      groups[key]['FanDuel'] = {
        home_team: key.split(' vs ')[0],
        away_team: key.split(' vs ')[1],
        bookmaker: 'FanDuel',
        totals_point: '--',
        home_team_price: '--',
        away_team_price: '--',
        under_price: '',
        over_price: '',
        // Add any other fields you want to display with default values
      };
    }
  }

  return Object.values(groups).map(group => [group['DraftKings'], group['FanDuel']]);
}

export function groupGameLogsNCAAF(logs) {
  const groups = {};

  for (let log of logs) {
    // Use the_home_team/the_away_team if available, otherwise fall back to home_team/away_team
    const homeTeam = log.the_home_team || log.home_team;
    const awayTeam = log.the_away_team || log.away_team;
    const key = `${homeTeam} vs ${awayTeam}`;

    if (!groups[key]) {
      groups[key] = {
        'DraftKings': null,
        'FanDuel': null
      };
    }

    groups[key][log.bookmaker] = log;
  }

  for (let key in groups) {
    const [homeTeam, awayTeam] = key.split(' vs ');
    if (!groups[key]['DraftKings']) {
      groups[key]['DraftKings'] = {
        the_home_team: homeTeam,
        the_away_team: awayTeam,
        home_team: homeTeam,
        away_team: awayTeam,
        bookmaker: 'DraftKings',
        totals_point: '--',
        home_team_price: '--',
        away_team_price: '--',
        under_price: '',
        over_price: '',
        // Add any other fields you want to display with default values
      };
    }
    if (!groups[key]['FanDuel']) {
      groups[key]['FanDuel'] = {
        the_home_team: homeTeam,
        the_away_team: awayTeam,
        home_team: homeTeam,
        away_team: awayTeam,
        bookmaker: 'FanDuel',
        totals_point: '--',
        home_team_price: '--',
        away_team_price: '--',
        under_price: '',
        over_price: '',
        // Add any other fields you want to display with default values
      };
    }
  }

  return Object.values(groups).map(group => [group['DraftKings'], group['FanDuel']]);
}

export function groupLogsByPitcher(logs) {
  // Create an empty object to store the groups
  const groups = {};

  // Loop over the logs
  for (let log of logs) {
    // If there's no group for this pitcher yet, create one
    if (!groups[log.player]) {
      groups[log.player] = {
        'DraftKings': null,
        'FanDuel': null
      };
    }

    // Add the log to the group for this pitcher
    groups[log.player][log.bookmaker] = log;
  }

  // Ensure each group has two entries
  for (let player in groups) {
    if (!groups[player]['DraftKings']) {
      groups[player]['DraftKings'] = {
        player: player,
        bookmaker: 'DraftKings',
        over_price: '--',
        under_price: '--',
        over_diff: '--',
        under_diff: '--',
        // Add any other fields you want to display with default values
      };
    }
    if (!groups[player]['FanDuel']) {
      groups[player]['FanDuel'] = {
        player: player,
        bookmaker: 'FanDuel',
        over_price: '--',
        under_price: '--',
        over_diff: '--',
        under_diff: '--',
        // Add any other fields you want to display with default values
      };
    }
  }

  // Convert the groups object to an array of arrays
  return Object.values(groups).map(group => [group['DraftKings'], group['FanDuel']]);
}


// export const formatTimeAgo = (lastUpdate) => {
//   const currentDate = new Date();
//   const updateDate = new Date(lastUpdate);

//   const timeDifference = currentDate - updateDate + 5 * 60 * 60 * 1000; // Add 5 hours to account for the time difference between UTC and EST
//   const minutes = Math.floor(timeDifference / 60000); // Convert milliseconds to minutes

//   if (minutes < 1) {
//     return 'Just now';
//   } else if (minutes === 1) {
//     return '1 minute ago';
//   } else if (minutes < 60) {
//     return `${minutes} minutes ago`;
//   } else {
//     const hours = Math.floor(minutes / 60);
//     if (hours === 1) {
//       return '1 hour ago';
//     } else if (hours < 24) {
//       return `${hours}+ hours ago`;
//     } else {
//       const days = Math.floor(hours / 24);
//       if (days === 1) {
//         return '1 day ago';
//       } else {
//         return `${days} days ago`;
//       }
//     }
//   }
// };

export const formatTimeAgo = (lastUpdate) => {
  const currentDate = new Date();
  let updateDate;
  let timeDifference;

  const isoFormat = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
  const gmtFormat = /^[MonTueWedThuFriSatSun]{3}, \d{2} [JanFebMarAprMayJunJulAugSepOctNovDec]{3} \d{4} \d{2}:\d{2}:\d{2} GMT$/;

  if (isoFormat.test(lastUpdate)) {
    // Handle ISO format
    updateDate = new Date(lastUpdate.replace(' ', 'T') + 'Z');
    timeDifference = currentDate - updateDate;
  } else if (gmtFormat.test(lastUpdate)) {
    // Handle GMT format
    updateDate = new Date(lastUpdate);
    timeDifference = currentDate - updateDate;
  } else {
    // Handle unknown format
    console.error('Unknown date format:', lastUpdate);
    return;
  }

  const minutes = Math.floor(timeDifference / 60000); // Convert milliseconds to minutes

  if (minutes < 1) {
    return 'Just now';
  } else if (minutes === 1) {
    return '1 minute ago';
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else {
    const hours = Math.floor(minutes / 60);
    if (hours === 1) {
      return '1 hour ago';
    } else if (hours < 24) {
      return `${hours}+ hours ago`;
    } else {
      const days = Math.floor(hours / 24);
      if (days === 1) {
        return '1 day ago';
      } else {
        return `${days}+ days ago`;
      }
    }
  }
};

export const formatDateForTooltip = (dateString) => {
  if (!dateString) return 'unknown time';
  
  let date;
  // Try different parsing methods
  try {
    // First try direct parsing
    date = new Date(dateString);
    
    // If that didn't work (Invalid Date), try other formats
    if (isNaN(date.getTime())) {
      // Try ISO format with T
      date = new Date(dateString.replace(' ', 'T'));
    }
    
    // If still invalid, try with Z
    if (isNaN(date.getTime())) {
      date = new Date(dateString.replace(' ', 'T') + 'Z');
    }
    
    // If still invalid, try parsing GMT format
    if (isNaN(date.getTime()) && dateString.includes('GMT')) {
      // GMT format should parse directly
      date = new Date(dateString);
    }
    
    // If we have a valid date, format it
    if (!isNaN(date.getTime())) {
      return date.toLocaleString('en-US', { 
        timeZone: 'America/New_York',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      });
    }
    
    // If all parsing attempts failed
    return 'unknown time';
  } catch (error) {
    console.error('Error parsing date:', error);
    return 'unknown time';
  }
};
